import { defineStore } from 'pinia'
import { getCustomerREQFn, getCustomerFn } from '@/api/message/customerCenter'

export const useCustomerCenterInfo = defineStore('customerCenterInfo', {
  state: () => ({
    progressLoading: false,
    customerList: [],
    listTotal: 0,
    CustomerDetailInfo: {},
    allChannelList: [],
    channelMap: new Map(),
    currentCustomerInfo: []
  }),
  getters: {
  },
  actions: {
    async getCustomerList (params) { // 獲取客戶列表
      this.progressLoading = true
      this.customerList = []

      const res = await getCustomerREQFn(params)
      const data = res.data.value
      if (data.code === 200) {
        this.customerList = data.data.data
        this.listTotal = data.data.total
        this.progressLoading = false
      }
      return data
    },
    async getCustomerDetail (customerId) { // 獲取客戶信息
      const res = await getCustomerFn(customerId)
      const data = res.data.value
      if (data.code === 200) {
        this.currentCustomerInfo = data.data
        this.currentCustomerInfo.channelName = this.channelMap.get(data.data.channelId)
      }
    }
  }
})
