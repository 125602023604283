<template>
  <div>
    <div v-if="selected.length > 0" class="mb-2">
      <CommonButton
        class="me-3"
        prepend-icon="fa-solid fa-circle-plus"
        variant="tonal"
        color="#2F88FF"
        name="添加標籤"
        style="height:40px;"
        @click="addTagBlock()"
      />
      <CommonButton
        prepend-icon="fa-regular fa-trash-can"
        variant="tonal"
        color="#f94545"
        name="刪除"
        style="height:40px;"
        @click="delSelectedCustomer()"
      />
    </div>

    <!-- 表格 -->
    <v-table :height="tableHeight" fixed-header>
      <thead>
        <tr>
          <th class="text-left">
            <v-checkbox
              v-model="selectAll"
              color="#448AFF"
              hide-details
              @change="selectAllFn()"
            />
          </th>
          <th v-for="item in tableHeader" :key="item" class="text-left" :style="item.width">
            {{ item.name }}
            <button v-if="['createdAt', 'lastChatAt'].includes(item.key)" @click="sortData(item.key)">
              <i class="fa-solid fa-sort ms-2" />
            </button>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="customerCenterInfo.progressLoading">
          <CommonProgressLoading />
        </tr>
        <template v-else>
          <template v-if="customerCenterInfo.customerList.length > 0">
            <tr v-for="i in customerCenterInfo.customerList" :key="i.customerId">
              <!-- 選取框 -->
              <td>
                <v-checkbox
                  v-model="selected"
                  :value="i.customerId"
                  color="#448AFF"
                  hide-details
                  @change="selectedFn()"
                />
              </td>

              <!-- 用戶名 -->
              <td>
                <div class="showDetail d-flex align-center" @click.stop="showDetail(i.customerId)">
                  <div style="position:relative;">
                    <v-avatar
                      v-if="i.avatar"
                      :image="userInfo.domain + i.avatar"
                      size="30"
                      class="me-6"
                    />
                    <v-avatar v-else color="pink" size="30" class="me-6">
                      <span style="font-size:13px;">{{ i.name?.substring(0, 1) }}</span>
                    </v-avatar>

                    <v-avatar
                      v-if="i.channelType === 'web'"
                      class="channelTypeIcon"
                      image="/images/sourceIcon/web.png"
                      size="18"
                    />
                    <v-avatar
                      v-if="i.channelType === 'tg'"
                      class="channelTypeIcon"
                      image="/images/sourceIcon/tg.png"
                      size="18"
                    />
                  </div>

                  <span class="font-weight-bold">{{ i.name }}</span>
                </div>
              </td>

              <!-- 郵箱 -->
              <td>{{ i.email }}</td>

              <!-- 電話 -->
              <td>{{ i.phone }}</td>

              <!-- 備註 -->
              <td>{{ i.remark }}</td>

              <!-- 加入時間 -->
              <td>{{ monthFormat(i.createdAt) }}</td>

              <!-- 最近對話時間 -->
              <td>{{ monthFormat(i.lastChatAt) }}</td>

              <!-- 客戶標籤 -->
              <td>
                <CommonTagDisplay
                  v-model="i.customerTags"
                  :all-tags="customerTagList"
                  @removeTag="removeCustomerTag(i, $event)"
                  @blur="addCustomerTags(i, $event)"
                />
              </td>

              <!-- 是否為黑名單 -->
              <td>{{ i.blacklist ? '已拉黑':'-' }}</td>

              <!-- 國家、城市低區 -->
              <td>
                {{ i.connectionInfo?.region?.country ? i.connectionInfo.region.country : '-' }}
                <br>
                {{ i.connectionInfo?.region?.city ? i.connectionInfo.region.city : '-' }}
              </td>

              <!-- 操作 -->
              <td>
                <CommonMenu :id-name="`customerCenter-${i.id}`">
                  <template #btnContent>
                    <i class="fa-solid fa-ellipsis" />
                  </template>
                  <template #menuContent>
                    <v-list class="list">
                      <v-list-item v-if="i.channelType === 'tg'">
                        <div class="d-flex align-center">
                          <v-list-item-title class="ms-2">
                            <i class="fa-solid fa-comment-dots me-2" />
                            回聊
                          </v-list-item-title>
                        </div>
                      </v-list-item>

                      <v-list-item @click="showDetailAndEdit(i.customerId)">
                        <div class="d-flex align-center">
                          <v-list-item-title class="ms-2">
                            編輯客戶
                          </v-list-item-title>
                        </div>
                      </v-list-item>
                      <v-list-item @click="delCustomer(i)">
                        <div class="d-flex align-center">
                          <v-list-item-title class="ms-2 colorRed">
                            <i class="fa-regular fa-trash-can me-2" />
                            刪除
                          </v-list-item-title>
                        </div>
                      </v-list-item>
                    </v-list>
                  </template>
                </CommonMenu>
              </td>
            </tr>
          </template>
          <template v-else>
            <div class="emptyData">
              無資料
            </div>
          </template>
        </template>
      </tbody>
    </v-table>

    <!-- 分頁 -->
    <div class="mt-3 tableFooter">
      <commonPagination
        :page-size-select-value="pageSizeSelectValue"
        :total="customerCenterInfo.listTotal"
        @handleSizeChange="handleSizeChangeFn"
        @handleCurrentChange="handleCurrentChangeFn"
      />
    </div>

    <!-- 刪除選取客戶 -->
    <LazyCommonDeleteDlg
      v-model:dlgVisible="deleteSelectedCustomerVisible"
      :sub-title="deleteCustomerSubTitle"
      :content="deleteCustomerContent"
      @deleteFn="deleteSelectedCustomerSubmit"
    />

    <!-- 刪除單一客戶 -->
    <LazyCommonDeleteDlg
      v-model:dlgVisible="deleteCustomerVisible"
      :data="deleteCustomerData"
      :sub-title="deleteCustomerSubTitle"
      :content="deleteCustomerContent"
      @deleteFn="deleteCustomerSubmit"
    />
  </div>
</template>

<script setup>
import { deleteCustomerFn, addCustomerTagsFn } from '@/api/message/customerCenter'

const userInfo = useUserInfo()
const customerCenterInfo = useCustomerCenterInfo()

const emit = defineEmits(['addTagFn'])

const {
  showCustomerDetail,
  getCustomerList,
  customerTagList,
  handleSizeChange,
  handleCurrentChange
} = inject('customerCenterProvide')

const tableHeight = ref('calc(100vh - 218px)')
const tableHeader = computed(() => [
  {
    name: '用戶名', // 用戶名
    key: 'name'
  },
  {
    name: '郵箱', // 郵箱
    key: 'email'
  },
  {
    name: '電話', // 電話
    key: 'phone'
  },
  {
    name: '備註', // 備註
    key: 'remark'
  },
  {
    name: '加入時間', // 加入時間
    key: 'createdAt'
  },
  {
    name: '最近對話時間', // 最近對話時間
    key: 'lastChatAt'
  },
  {
    name: '客戶標籤', // 客戶標籤
    key: 'tags'
  },
  {
    name: '是否為黑名單', // 是否為黑名單
    key: 'blacklist'
  },
  {
    name: '國家/城市地區', // 國家/城市地區
    key: 'country'
  },
  {
    name: '操作' // 操作
  }
])

const props = defineProps({
  searchParams: {
    type: Object,
    default: () => ({})
  },
  pageSizeSelectValue: {
    type: Array,
    default: () => []
  }
})

// 全選、單選
const selectAll = ref(false)
const selected = ref([])
const selectAllFn = () => {
  if (selectAll.value) {
    tableHeight.value = 'calc(100vh - 266px)'
    customerCenterInfo.customerList.forEach((i) => {
      selected.value.push(i.customerId)
    })
  } else {
    selected.value = []
    tableHeight.value = 'calc(100vh - 218px)'
  }
}
const selectedFn = () => {
  if (selected.value.length > 0) {
    tableHeight.value = 'calc(100vh - 266px)'
    selectAll.value = true
  } else {
    tableHeight.value = 'calc(100vh - 218px)'
    selectAll.value = false
  }
}

// 排序(加入時間、最近對話時間)
const sortState = reactive({
  createdAt: { active: false, direction: 'asc' },
  lastChatAt: { active: false, direction: 'asc' }
})
const sortData = (key) => {
  if (sortState[key]) {
    sortState[key].active = !sortState[key].active
    sortState[key].direction = sortState[key].active ? 'asc' : 'desc'
    sortGetCustomerList(key, sortState[key].direction)
  }
}
const sortGetCustomerList = async (orderByValue, directionValue) => {
  const params = reactive({
    pageNum: 0,
    pageSize: props.searchParams.pageSize,
    orderBy: orderByValue,
    direction: directionValue
  })
  await customerCenterInfo.getCustomerList(params)
}

// 顯示客戶詳情
const showDetail = (customerId) => {
  showCustomerDetail(customerId, 'view')
}

const showDetailAndEdit = (customerId) => {
  showCustomerDetail(customerId, 'edit')
}

// 切分頁、筆數
const handleSizeChangeFn = (val) => {
  handleSizeChange(val)
}
const handleCurrentChangeFn = (val) => {
  handleCurrentChange(val)
}

// 添加標籤區塊
const addTagBlock = () => {
  emit('addTagFn', selected.value)
}

// 對話標籤
const addCustomerTags = (item, tags) => {
  const itemTagIds = item.customerTags ? item.customerTags.map(tag => tag.id) : []
  const tagsIds = tags.map(tag => tag.id)

  if (compareArray(itemTagIds, tagsIds)) {
    return
  }

  updateCustomerTag(item, tags, true)
}

// 更新客戶標籤
const updateCustomerTag = async (item, data, isAdd) => {
  const postData = data.map(i => i.id)

  const res = await addCustomerTagsFn(item.customerId, postData)
  if (res.data.value.code === 200) {
    ElMessage.success({ message: isAdd ? '標籤更新成功' : '標籤刪除成功' })

    // 更新當前列表
    item.customerTags = data
  }
}

// 刪除標籤
const removeCustomerTag = (item, tag) => {
  const updateTags = item.customerTags.filter(item => item.id !== tag.id)
  updateCustomerTag(item, updateTags, false)
}

// 比對兩個陣列是否相同
const compareArray = (arr1, arr2) => {
  return arr1.length === arr2.length &&
         new Set(arr1).size === new Set(arr2).size &&
         [...new Set(arr1)].every(id => new Set(arr2).has(id))
}

// 選取刪除客戶
const deleteSelectedCustomerVisible = ref(false)
const deleteCustomerSubTitle = ref('')
const deleteCustomerContent = ref('')
const delSelectedCustomer = () => {
  if (selected.value.length > 0) {
    deleteCustomerSubTitle.value = '刪除所有勾選共 ' + selected.value.length + ' 筆的客戶資料?'
    deleteCustomerContent.value = '將刪除被勾選的資訊，總共' + selected.value.length + '筆，您確定要全部刪除嗎？'
  }

  deleteSelectedCustomerVisible.value = true
}
const deleteSelectedCustomerSubmit = () => {
  if (selected.value.length > 0) {
    deleteCustomer(selected.value)
  }

  selected.value = []
  selectAll.value = false
  deleteSelectedCustomerVisible.value = false
}

// 刪除單一客戶
const deleteCustomerVisible = ref(false)
const deleteCustomerData = ref()
const delCustomer = (item) => {
  deleteCustomerData.value = item
  deleteCustomerSubTitle.value = '刪除客戶【' + item.name + '】?'
  deleteCustomerContent.value = '將刪除客戶【' + item.name + '】，您確定要刪除嗎？'
  deleteCustomerVisible.value = true
}
const deleteCustomerSubmit = () => {
  deleteCustomer(deleteCustomerData.value.customerId)
  deleteCustomerVisible.value = false
}

// 刪除
const deleteCustomer = async (ids) => {
  const params = {
    ids
  }

  const onRes = ({ response }) => {
    const res = response._data
    if (res.code === 200) {
      ElMessage.success({ message: '刪除成功' }) // 刪除成功

      getCustomerList(props.searchParams)
    }
  }
  await deleteCustomerFn(params, onRes)
}

</script>

<style lang="scss" scoped>
.tableFooter {
  display: flex; align-items: center; justify-content:flex-end;
}
.showDetail {
  color: #0091EA;
  cursor:pointer;
}
.channelTypeIcon {
  position: absolute; right: 12px; bottom: 0;
}
</style>
