<template>
  <div id="customerCenter" class="relative" @click="closeDialog()">
    <div class="d-flex align-center justify-lg-space-between">
      <!-- 篩選 -->
      <SearchBar
        :is-show-search-input="true"
        :is-show-clear-btn="isShowClearBtn"
        @showSearchBlockFn="showSearchBlockFn"
        @searchSubmitFn="searchSubmit"
        @clearSearchFn="clearSearchFn"
      />

      <!-- 清空 -->
      <CommonButton
        v-if="customerCenterInfo.customerList.length > 0 && hasQueryConditions() "
        prepend-icon="fa-regular fa-trash-can"
        variant="tonal"
        style="height:41px;"
        color="#f94545"
        name="清空客戶"
        @click="deleteAll()"
      />
    </div>

    <!-- 客戶表格 -->
    <CustomerCenterTable
      :page-size-select-value="pageSizeSelectValue"
      :search-params="searchParams"
      @addTagFn="addTagFn($event)"
    />

    <!-- 進階篩選 -->
    <div v-if="showSearchBlock" id="searchBlock" @click.stop>
      <CustomerCenterSearch
        :customer-tag-list="customerTagList"
        :search-params="searchParams"
        @advancedSearchSubmit="advancedSearchSubmit"
        @closeSearchBlock="closeSearchBlock"
      />
    </div>

    <!-- 客戶細節 -->
    <div v-if="showDetail" id="detailBlock" @click.stop>
      <ConvDetail
        :tab="userInfoTab"
        :mode="userInfoTabMode"
      />
    </div>

    <!-- 添加標籤 -->
    <div v-if="dlgAddTagVisible">
      <LazyCustomerCenterAddTag
        :is-show="dlgAddTagVisible"
        :selected-customer-id-list="selectedCustomerIdList"
        @closeDlg="closeAddTagDlg()"
      />
    </div>

    <!-- 刪除所有客戶 -->
    <LazyCommonDeleteDlg
      v-model:dlgVisible="deleteAllCustomerVisible"
      :sub-title="deleteAllCustomerSubTitle"
      :content="deleteAllCustomerContent"
      @deleteFn="deleteAllCustomerSubmit"
    />
  </div>
</template>

<script setup>
import { deleteAllCustomerFn } from '@/api/message/customerCenter'

const customerCenterInfo = useCustomerCenterInfo()
const historyInfo = useHistoryInfo()

const pageSizeDefault = ref(20)

onBeforeMount(() => {
  fetchData()
})

const fetchData = async () => {
  await getCustomerTags()
  await getCustomerList(searchParams)
}

// 獲取客戶列表
const getCustomerList = async (params) => {
  await customerCenterInfo.getCustomerList(params)
}

const defaultSearchParams = reactive({
  pageNum: 0,
  pageSize: pageSizeDefault.value,
  condition: '', // 模糊查詢條件
  startCreatedAt: '', // 加入時間 - 開始
  endCreatedAt: '', // 加入時間 - 結束
  startLastChatAt: '', // 最後聊天時間 - 開始
  endLastChatAt: '', // 最後聊天時間 - 結束
  name: '', // 用戶名
  nickName: '', // 暱稱
  email: '', // 信箱
  phone: '', // 手機
  remark: '', // 備註
  channelType: '', // 渠道類型
  tags: '', // 客戶標籤
  isBlack: '', // 是否是黑名單
  orderByColumn: '', // 排序的欄位
  sortDirection: '' // 排序方式 (asc/desc)
})

const searchParams = reactive({ ...defaultSearchParams })

// 重置搜尋條件
const resetSearchParams = () => {
  Object.assign(searchParams, defaultSearchParams)
}

// 是否有其他查詢條件 true : "清空按鈕"不顯示
const hasQueryConditions = () => {
  const keysToIgnore = ['pageNum', 'pageSize', 'orderByColumn', 'sortDirection']
  for (const key in searchParams) {
    if (!keysToIgnore.includes(key)) {
      if (searchParams[key] !== defaultSearchParams[key]) {
        return false
      }
    }
  }
  return true
}

// 快捷篩選
const searchSubmit = (params) => {
  searchParams.condition = params.input
  getCustomerList(searchParams)
}

// 進階篩選
const showSearchBlock = ref(false)
const showSearchBlockFn = () => {
  showSearchBlock.value = true
}
// 進階搜尋提交
const advancedSearchSubmit = (formData) => {
  resetSearchParams()
  Object.assign(searchParams, formData)
  searchParams.pageSize = pageSizeDefault.value
  getCustomerList(searchParams)
  showSearchBlock.value = false
  isShowClearBtn.value = true
  closeSearchBlock()
}
// 清除進階搜尋
const isShowClearBtn = ref(false)
const clearSearchFn = () => {
  resetSearchParams()
  searchParams.pageSize = pageSizeDefault.value
  getCustomerList(searchParams)
  isShowClearBtn.value = false
}
// 關閉進階搜尋
const closeSearchBlock = () => {
  showSearchBlock.value = false
}

// 關閉彈窗
const closeDialog = () => {
  if (showDetail.value === true || showSearchBlock.value === true) {
    showDetail.value = false
    showSearchBlock.value = false
  }
}

// 切分頁、筆數
const pageSizeSelectValue = [pageSizeDefault.value, 50, 100]
const handleSizeChange = (val) => {
  pageSizeDefault.value = val
  searchParams.pageSize = val
  getCustomerList(searchParams)
}
const handleCurrentChange = (val) => {
  const page = val - 1
  searchParams.pageNum = page
  getCustomerList(searchParams)
}

// 客戶標籤
const customerTagList = ref([])
const getCustomerTags = async () => {
  const result = await historyInfo.getCustomerTags()
  customerTagList.value = result
}

// 客戶詳情 ---
const showDetail = ref(false)
const showCustomerDetail = async (customerId, mode) => {
  showDetail.value = true
  userInfoTabMode.value = mode
  await historyInfo.getConversationDetailByCustomerId(customerId)
}
// 開啟訪客詳情tab
const userInfoTab = ref('userInfo')
// 開啟訪客詳情模式 view/edit
const userInfoTabMode = ref('view')

// 已選擇的客戶列表
const selectedCustomerIdList = ref([])
// 批量添加標籤
const dlgAddTagVisible = ref(false)
const addTagFn = (selected) => {
  selectedCustomerIdList.value = selected
  dlgAddTagVisible.value = true
}
const closeAddTagDlg = () => {
  dlgAddTagVisible.value = false
}

// 刪除所有客戶
const deleteAllCustomerVisible = ref(false)
const deleteAllCustomerSubTitle = ref('')
const deleteAllCustomerContent = ref('')

const deleteAll = () => {
  deleteAllCustomerSubTitle.value = '刪除所有的客戶資料？'
  deleteAllCustomerContent.value = '將刪除所有的客戶資料，總共' + customerCenterInfo.listTotal + '筆，您確定要全部刪除嗎？'

  deleteAllCustomerVisible.value = true
}

const deleteAllCustomerSubmit = async () => {
  deleteAllCustomerVisible.value = false

  const onRes = ({ response }) => {
    const res = response._data
    if (res.code === 200) {
      ElMessage.success({ message: '刪除成功' }) // 刪除成功

      getCustomerList(searchParams)
    }
  }
  await deleteAllCustomerFn(onRes)
}

provide('customerCenterProvide', {
  getCustomerList,
  resetSearchParams,
  showCustomerDetail,
  isShowClearBtn,
  customerTagList,
  handleSizeChange,
  handleCurrentChange
})
</script>

<style lang="scss" scoped>
#toolBar {
  display: flex;
  justify-content: space-between
}
#searchBlock {
  position: absolute; top: 0; left: 0px; z-index: 9;
  width: 424px; height: 100%;
  background: #fff;
  border: 1px solid #fbfbfb;
  box-shadow: 5px 0 5px rgba(223, 223, 223, 0.5);
  padding: 20px 30px;
}
#detailBlock {
  position: absolute; top: 0; right: 0px; z-index: 9;
  width: 700px; height: 100%;
  background: #fff;
  border: 1px solid #fbfbfb;
  box-shadow: -5px 0 5px rgba(223, 223, 223, 0.5);
  padding: 20px 30px;
}
:deep(.el-date-editor) {
  height: 41px;
  width: 356px;
}
:deep(.el-date-editor i) {
  margin-right: 10px;
}
</style>
