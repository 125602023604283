<template>
  <div class="d-flex align-center justify-lg-space-between">
    <div class="text-h6 font-weight-bold">
      進階篩選
    </div>
    <v-btn variant="text" density="default" icon="fa-regular fa-circle-xmark" @click="close()" />
  </div>
  <div class="mt-6 searchForm">
    <v-form ref="form">
      <v-row>
        <v-col cols="12" sm="">
          <div class="mb-5">
            <div class="text-body-2 ms-1 mb-1">
              加入時間段
            </div>
            <CommonDatePicker
              :default-start-time="formData.startCreatedAt"
              :default-end-time="formData.endCreatedAt"
              @searchDate="createdAtSearchDate"
            />
          </div>
          <div class="mb-5">
            <div class="text-body-2 ms-1 mb-1">
              最近對話時間段
            </div>
            <CommonDatePicker
              :default-start-time="formData.startLastChatAt"
              :default-end-time="formData.endLastChatAt"
              @searchDate="lastChatAtSearchDate"
            />
          </div>
          <div class="mb-5">
            <div class="text-body-2 ms-1 mb-1">
              用戶姓名
            </div>
            <v-text-field
              v-model="formData.name"
              type="text"
              variant="solo-filled"
              density="compact"
              hide-details
            />
          </div>
          <div class="mb-5">
            <div class="text-body-2 ms-1 mb-1">
              電話
            </div>
            <v-text-field
              v-model="formData.phone"
              variant="solo-filled"
              density="compact"
              hide-details
            />
          </div>
          <div class="mb-5">
            <div class="text-body-2 ms-1 mb-1">
              郵箱
            </div>
            <v-text-field
              v-model="formData.email"
              variant="solo-filled"
              density="compact"
              hide-details
            />
          </div>
          <div class="mb-5">
            <div class="text-body-2 ms-1 mb-1">
              客戶標籤
            </div>
            <CommonMultipleSelect
              :multiple-select="customerTagList"
              title="name"
              item-value="id"
              @selectFn="selectCustomerTag"
            />
          </div>
          <div class="mb-5">
            <div class="text-body-2 ms-1 mb-1">
              客戶備註
            </div>
            <v-text-field
              v-model="formData.remark"
              variant="solo-filled"
              density="compact"
              hide-details
            />
          </div>
          <div class="mb-5 selectOne">
            <div class="text-body-2 ms-1 mb-1">
              是否加入黑名單
            </div>
            <v-select
              v-model="formData.isBlack"
              :items="isBlacklistData"
              item-title="isBlacklistName"
              item-value="isBlacklistValue"
              density="compact"
              variant="solo-filled"
              hide-details
              clearable
            />
          </div>
        </v-col>
      </v-row>

      <div class="searchBtnBlock">
        <CommonButton class="me-3" variant="tonal" color="#2E2E2E" name="清除" @click="reset()" />
        <CommonButton
          class="searchBtn"
          variant="flat"
          color="#448AFF"
          style="color:#fff; width:100px;"
          name="搜尋"
          @click="searchSubmit()"
        />
      </div>
    </v-form>
  </div>
</template>

<script setup>
const emit = defineEmits(['closeSearchBlock', 'advancedSearchSubmit'])

const props = defineProps({
  customerTagList: {
    type: Array,
    default: () => []
  },
  searchParams: {
    type: Object,
    default: () => ({})
  }
})

onBeforeMount(() => {
  loadFormData()
})

// 表單資料
const defaultFormData = reactive({
  startCreatedAt: null, // 加入時間段(開始時間)
  endCreatedAt: null, // 加入時間段(結束時間)
  startLastChatAt: null, // 最近對話時間段(開始時間)
  endLastChatAt: null, // 最近對話時間段(結束時間)
  name: '', // 用戶姓名
  nickName: '', // 暱稱
  email: '', // 信箱
  phone: '', // 手機
  remark: '', // 備註
  channelType: '', // 渠道類型
  tags: '', // 客戶標籤
  country: '', // 國家
  isBlack: '' // 是否加入黑名單
})

const formData = reactive({ ...defaultFormData })

const createdAtSearchDate = (val) => {
  formData.startCreatedAt = val.startTime
  formData.endCreatedAt = val.endTime
}

const lastChatAtSearchDate = (val) => {
  formData.startLastChatAt = val.startTime
  formData.endLastChatAt = val.endTime
}

const isBlacklistData = [ // 是否加入黑名單
  {
    isBlacklistName: '是',
    isBlacklistValue: 'true'
  }, {
    isBlacklistName: '否',
    isBlacklistValue: 'false'
  }
]

// 清除form
const form = ref('')
const reset = () => {
  resetFormData()
  form.value.reset()
}

// 重置表單資料
const resetFormData = () => {
  Object.assign(formData, defaultFormData)
}

// 關閉 searchBlock
const close = () => {
  emit('closeSearchBlock')
}

// 客戶標籤
const selectCustomerTag = (val) => {
  const selectCustomerValue = Object.values(val).join(',')
  formData.tags = selectCustomerValue
}

// 進階篩選
const searchSubmit = () => {
  emit('advancedSearchSubmit', formData)
}

// 合併表單資料到搜尋條件
const loadFormData = () => {
  Object.keys(formData).forEach((key) => {
    formData[key] = props.searchParams[key]
  })
}

</script>

<style lang="scss" scoped>
  :deep(.v-field--variant-solo-filled) {
    box-shadow: none;
  }
  .searchForm {
    .v-row {
      height: calc(100vh - 218px);
      overflow: scroll;
    }

    :deep(.el-date-editor) {
      height: 52px;
    }
  }
  .searchBtnBlock {
    display: flex; justify-content: flex-end;
  }

  // 多選select
  :deep(.v-select--multiple) {
    .v-chip {
      margin: 2px 0;
    }
    .v-field__input {
      padding-bottom: 11px;
    }
  }
  :deep(.v-text-field .v-field--no-label input) {
    height: 50px;
  }
  .selectOne {
    :deep(.v-select .v-field) {
      padding-top: 4px;
      padding-bottom: 4px;
    }
  }
</style>
